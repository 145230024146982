import { createRoot } from 'react-dom/client';

import Root from '../app/components/Root';

(() => {
	const rootEl = document.getElementById('app-root');
	if (!rootEl) throw new Error('Could not find app root');

	const root = createRoot(rootEl);
	root.render(<Root />);
})();
