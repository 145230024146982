import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '../utils';

import App from './App';
import GlobalStyle from './GlobalStyle';

export default function Root() {
	return (
		<QueryClientProvider client={queryClient}>
			<GlobalStyle />
			<App />
		</QueryClientProvider>
	);
}
