import { Route, Switch } from 'wouter';

import AuthGuard from './AuthGuard';
import Home from './Home';
import Login from './Login';
import NestedRoute from './NestedRoute';
import NoMatch from './NoMatch';
import SessionProvider from './SessionProvider';

export default function App() {
	return (
		<Switch>
			<Route path="/login">
				<Login />
			</Route>
			<NestedRoute base="/">
				<AuthGuard>
					<SessionProvider>
						<Route path="/">
							<Home />
						</Route>
					</SessionProvider>
				</AuthGuard>
			</NestedRoute>
			<Route>
				<NoMatch />
			</Route>
		</Switch>
	);
}
