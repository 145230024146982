import { ReactNode } from 'react';
import { Router, useLocation, useRouter } from 'wouter';

type Props = {
	base: string;
	children: ReactNode;
};

export default function NestedRoute({ base, children }: Props) {
	const router = useRouter();
	const [parentLocation] = useLocation();

	const nestedBase = `${router.base}${base}`;

	if (!parentLocation.startsWith(nestedBase)) return null;

	return (
		<Router key={nestedBase} base={nestedBase}>
			{children}
		</Router>
	);
}
