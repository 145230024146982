import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	padding: 24px;
`;

const Content = styled.div`
	margin-top: 12px;
	text-align: center;
`;

export default function Login() {
	return (
		<Container>
			<h1>Hello stranger</h1>
			<Content>
				<p>Welcome to Shenanigans!</p>
				<p>
					<a href="/auth/discord">Log in with Discord</a> to get started.
				</p>
			</Content>
		</Container>
	);
}
