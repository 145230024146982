import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	html {
		box-sizing: border-box;
		background: #191919;
		color: #e0dcdc;
		font: 300 16px/24px Roboto, sans-serif;
	}

	*, *::before, *::after {
		box-sizing: inherit;
	}

	body {
		min-height: 100vh;
		margin: 0;
	}

	h1 {
		margin: 0;
		font-size: 32px;
		font-weight: 400;
		line-height: 48px;
	}

	p {
		margin: 0;
	}

	a {
		color: #729adf;
		font-weight: 400;
		text-decoration: none;
		transition: color .2s ease-out;

		&:hover {
			color: #a4beea;
		}
	}
`;
