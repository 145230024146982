import { useSessionUser } from '../hooks';

export default function Home() {
	const user = useSessionUser();

	return (
		<div>
			<p>Hello {user.discordTag}.</p>
		</div>
	);
}
