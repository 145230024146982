import { useContext } from 'react';

import { Session } from '../contexts';
import { User } from '../types';

export default function useSessionUser(): User {
	const ctx = useContext(Session);
	if (!ctx) {
		throw new Error(
			'Cannot use `useSessionUser` outside of a `SessionProvider`.',
		);
	}

	return ctx.user;
}
