import { useSuspenseQuery } from '@tanstack/react-query';
import { ReactNode } from 'react';

import { Session } from '../contexts';
import { User } from '../types';
import { apiClient } from '../utils';

type Props = {
	children: ReactNode;
};

export default function SessionProvider({ children }: Props) {
	const { data: user } = useSuspenseQuery({
		queryKey: ['me'],
		queryFn: () => apiClient.get<User>('/me').then((res) => res.data),
	});

	return <Session.Provider value={{ user }}>{children}</Session.Provider>;
}
