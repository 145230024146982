import axios from 'axios';

import getEnv from './getEnv';

export default axios.create({
	baseURL: getEnv('API_URL'),
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	withCredentials: true,
});
