import { isAxiosError } from 'axios';
import { Component, ReactNode, Suspense } from 'react';
import { navigate } from 'wouter/use-location';

type Props = {
	children: ReactNode;
};
type State = {
	hasError: boolean;
};

export default class AuthGuard extends Component<Props, State> {
	state = { hasError: false };

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: unknown) {
		if (!isAxiosError(error)) return;

		if (error.response?.status === 401) {
			navigate('/login');
		}
	}

	render() {
		if (this.state.hasError) {
			return <div>Uh-oh, something went wrong.</div>;
		}

		return (
			<Suspense fallback={<p>Loading...</p>}>{this.props.children}</Suspense>
		);
	}
}
